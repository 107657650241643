//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import ExporterFormScan from '@/modules/exporter/components/4-exporter-form-scan';
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-exporter-scan-page',

  props: ['sellerId'],

  components: {
    [ExporterFormScan.name]: ExporterFormScan,
  },

  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      // record: 'exporter/form/record',
      findLoading: 'exporter/form/findLoading',
      // saveLoading: 'exporter/form/saveLoading',
    }),
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.exporter.label')  +
            ' | ' +
            this.i18n('entities.exporter.pleaseScanItems') 
    },
  },

  // async creaded() {
  //   await firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async querySnapshot => {
  //      console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFetchItemsInBackground({ filter: { status: 'inStock' } })
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },

  methods: {
    ...mapActions({
      // doCreate: `exporter/form/doCreate`,
      // doUpdate: `exporter/form/doUpdate`,
      // doFetchSeller: 'seller/list/doFetch',
      doFetchItemsInBackground: 'exporter/list/doFetchInBackground',
      doFetchItems: 'exporter/list/doFetchIn',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    // doUpdate(payload) {
    //   return this.$store.dispatch(
    //     `${this.tab}/form/doUpdate`, payload
    //   );
    // },
    doCancel() {
      routerAsync().push('/export');
    },

    async doSubmit() {
      
      // return this.doCreate(payload);
    },
  },
};
